import request from '@/utils/requestEhpc'
const message = '/service-msg-provider'
// 获取未读消息下分类消息 T
export function getUnReadSortMessage(params) {
  return request({
    url: message + '/v1/messages/type/unread',
    method: 'get',
    params
  })
}

// 获取消息下分类消息 T
export function getAllMessage(params) {
  return request({
    url: message + '/v1/messages/type',
    method: 'get',
    params
  })
}

// 消息标为已读 T
export function setMessageRead(data) {
  return request({
    url: message + '/v1/messages/read',
    method: 'post',
    data
  })
}

// 删除已读消息 T
export function delMessageRead(data) {
  return request({
    url: message + '/v1/messages/read',
    method: 'patch',
    data
  })
}
export function getMessageDetail(msgID) {
  return request({
    url: message + '/v1/messages/template/msgId',
    method: 'get',
    params: {
      msgID
    }
  })
}
