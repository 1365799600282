<template>
  <div ref="scrollApplication" class="manual-application">
    <div class="video">
      <!-- <img src="@/assets/manual/video2.png" alt="超算云桌面用户手册"> -->
      <video src="@/assets/viod/install.mp4" controls="controls" />

    </div>
    <div class="content">
      <div id="manualInstall" class="model-box not-home">
        <h1>如何安装应用</h1>
        <div class="box-content">
          1、打开应用管家
          <div>
            2、找到要安装的应用，右键应用，点击“安装”即可
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/install.png" alt="超算云桌面用户手册" @click="showImagePreview(1)"> -->
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div id="manualUninstall" class="model-box not-home">
        <h1>如何卸载应用</h1>
        <div class="box-content">
          方式1：
          <div class="space-tab">
            点击左上角的图标
          </div>
          <div class="space-tab">
            右击要卸载的应用，点击“卸载”，即完成应用卸载
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/uninstall1.png" alt="超算云桌面用户手册" @click="showImagePreview(2)"> -->
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl2" alt="超算云桌面用户手册">
            </viewer>
          </div>
          方式2：
          <div class="space-tab">
            打开应用管家
          </div>
          <div class="space-tab">
            找到要卸载的应用，右键应用，点击“卸载”即可
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/uninstall2.png" alt="超算云桌面用户手册" @click="showImagePreview(3)"> -->
            <viewer :images="imageUrl3" :options="options">
              <img :src="imageUrl3" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/install-big.png'),
      imageUrl2: require('../assets/manual/uninstall1-big.png'),
      imageUrl3: require('../assets/manual/uninstall2-big.png')
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (sessionStorage.manualAppIdType === 'uninstall') {
        document.querySelector('#manualUninstall').scrollIntoView({ behavior: 'smooth' })
      }
    })
  },
  methods: {
    showImagePreview(val) {
      if (document.body.getElementsByClassName('previewContainer').length !== 0) {
        document.body.removeChild(document.body.getElementsByClassName('previewContainer')[0])
      }
      const image = new Image()
      // image.src = this.imageUrl
      if (val === 1) {
        image.src = this.imageUrl1
      } else if (val === 2) {
        image.src = this.imageUrl2
      } else {
        image.src = this.imageUrl3
      }
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div')
        previewContainer.className = 'previewContainer'
        previewContainer.style.position = 'fixed'
        previewContainer.style.top = 0
        previewContainer.style.bottom = 0
        previewContainer.style.left = 0
        previewContainer.style.right = 0
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContainer.style.display = 'flex'
        // previewContainer.style.justifyContent = 'center'
        previewContainer.style.alignItems = 'center'
        document.body.appendChild(previewContainer)
        // 在弹出层中添加图片
        const previewImage = document.createElement('img')
        // previewImage.src = this.imageUrl
        if (val == 1) {
          previewImage.src = this.imageUrl1
        } else if (val === 2) {
          previewImage.src = this.imageUrl2
        } else {
          previewImage.src = this.imageUrl3
        }
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewContainer.appendChild(previewImage)
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer)
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.manual-application{
  video{
    width: 100%;
    height: 100%;
  }
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
}
</style>
