<template>
  <div class="manual-vscode">
    <div class="video">
      <img src="@/assets/manual/video4.png" alt="超算云桌面用户手册">
    </div>
    <div class="content">
      <div class="model-box not-home">
        <h1>Vscode可进行编辑保存文件管理家目录下的文件</h1>
        <div class="box-content">
          <div>
            1、在桌面双击打开或者右键选择启动打开vscode
          </div>
          <div>
            2、进入vscode应用，点击启动按钮，默认指定工作空间到/home/coder/project
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/vscode1.png" alt="超算云桌面用户手册" @click="showImagePreview(1)"> -->
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="超算云桌面用户手册">
            </viewer>
          </div>
          <div>
            3、选择想要编辑的文件单击打开，在右侧进行编辑，编辑修改后文件会自动保存
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/vscode2.png" alt="超算云桌面用户手册" @click="showImagePreview(2)"> -->
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl2" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div class="model-box not-home">
        <h1>Vscode如何安装汉化</h1>
        <div class="box-content">
          <div>
            1、在插件市场中下载汉化插件，如<a>https://open-vsx.org/extension/MS-CEINTL/vscode-language-pack-zh-hans</a>，点击紫色下载按钮进行下载
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/vscode2.png" alt="超算云桌面用户手册" @click="showImagePreview(2)"> -->
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl3" alt="超算云桌面用户手册">
            </viewer>
          </div>
          <div>
            2、在文件管理中上传刚刚下载的插件
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/vscode1.png" alt="超算云桌面用户手册" @click="showImagePreview(1)"> -->
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl4" alt="超算云桌面用户手册">
            </viewer>
          </div>
          <div>
            3、在vscode应用扩展中选择Install from VSIX
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/vscode2.png" alt="超算云桌面用户手册" @click="showImagePreview(2)"> -->
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl5" alt="超算云桌面用户手册">
            </viewer>
          </div>
          <div>
            4、在/home/coder/project下选择刚刚上传的插件，
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/vscode2.png" alt="超算云桌面用户手册" @click="showImagePreview(2)"> -->
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl6" alt="超算云桌面用户手册">
            </viewer>
          </div>
          <div>
            5、重启vscode完成安装
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/vscode1-big.png'),
      imageUrl2: require('../assets/manual/vscode2-big.png'),
      imageUrl3: require('../assets/manual/vscode3-big.png'),
      imageUrl4: require('../assets/manual/vscode4-big.png'),
      imageUrl5: require('../assets/manual/vscode5-big.png'),
      imageUrl6: require('../assets/manual/vscode6-big.png')
    }
  },
  methods: {
    showImagePreview(val) {
      if (document.body.getElementsByClassName('previewContainer').length !== 0) {
        document.body.removeChild(document.body.getElementsByClassName('previewContainer')[0])
      }
      const image = new Image()
      // image.src = this.imageUrl
      if (val === 1) {
        image.src = this.imageUrl1
      } else {
        image.src = this.imageUrl2
      }
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div')
        previewContainer.className = 'previewContainer'
        previewContainer.style.position = 'fixed'
        previewContainer.style.top = 0
        previewContainer.style.bottom = 0
        previewContainer.style.left = 0
        previewContainer.style.right = 0
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContainer.style.display = 'flex'
        // previewContainer.style.justifyContent = 'center'
        previewContainer.style.alignItems = 'center'
        document.body.appendChild(previewContainer)
        // 在弹出层中添加图片
        const previewImage = document.createElement('img')
        // previewImage.src = this.imageUrl
        if (val == 1) {
          previewImage.src = this.imageUrl1
        } else {
          previewImage.src = this.imageUrl2
        }
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewContainer.appendChild(previewImage)
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer)
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.manual-vscode{
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
}
</style>
