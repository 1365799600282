<template>
  <div>
    <div
      v-if="usersManualId !== 1
        && usersManualId !== 2
        && usersManualId !== 3149252963594628
        && usersManualId !== 6153013083878343
        && usersManualId !== 1221482298789444
        && usersManualId !== 6258729541266953
        && usersManualId !== 3350359504604818
        && usersManualId !== 8889162036467440
        && usersManualId !== 8889162036467443
        && usersManualId !== 8889162036467447
        && usersManualId !== 2059033130546053
        && usersManualId !== 8889162036467442
        && usersManualId !== 3143428603900800
        && usersManualId !== 8889162036467449
        && usersManualId !== 3296931928216553
        && usersManualId !== 7279991280277965
        && usersManualId !== 2946147018625071
        && usersManualId !== 8889162036467441
        && usersManualId !== 2108023696651968
        && usersManualId !== 3
        && usersManualId !== 5
        && usersManualId !== 3145878294710521
        && usersManualId !== 7100048350041969
        && usersManualId !== 6
        && usersManualId !== 0
        && usersManualId !== -1
        && usersManualId !== -2
        && usersManualId !== -3
        && usersManualId !== -4
        && usersManualId !== 8889162036467499
        && usersManualId !== 8889162036467453
        && usersManualId !== 8889162036467452
        && usersManualId !== 8889162036467451
        && usersManualId !== 8889162036467450
        && usersManualId !== 1382943853206252
        && usersManualId !== 7"
    >新手入门</div>
    <div v-else class="return-btn" @click="returnHandle">
      <el-icon><ArrowLeft /></el-icon>
      返回
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    usersManualId() {
      var usersManualId = null
      usersManualId = this.$store.state.core.usersManualId
      return usersManualId
    }
  },
  methods: {
    returnHandle() {
      if (this.usersManualId === 3149252963594628) {
        sessionStorage.manualAppIdType = 'install'
      }
      if (this.usersManualId === 7100048350041969) {
        sessionStorage.manualFileIdType = 'file'
      }
      const obj = {
        usersManualCount: null,
        id: null
      }
      this.$store.dispatch('core/usersManual', obj)
    }
  }
}
</script>

<style lang="less" scoped>
.return-btn{
  cursor: pointer;
  .el-icon svg{
    width: 12px;
    height: 12px;
  }
}
</style>
