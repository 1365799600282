<template>
  <div
    class="icon-item"
    :class="{selected:item.selected}"
    :style="{
      height: cell.height + 'px',
      width: cell.width + 'px',
      top: position.y + 'px',
      left: position.x + 'px'
    }"
    @contextmenu="onContextmenu($event)"
  >
    <div class="icon-body" draggable="true" @click="onClick($event)" @dblclick="onDblclick()" @dragstart="onDragstart()" @dragend="onDragend()">
      <!-- <div class="icon" :class="item.icon"></div> -->
      <div class="image">
        <!-- {{ item.icon }} -->
        <img :src="item.icon" alt="">
      </div>
      <div class="text">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    item: Object,
    grid: Object,
    cell: Object,
    order: Number
    // select: Function,
    // dragstart: Function
  },
  data() {
    return {
      position: {
        x: 0,
        y: 0
      },
      dragging: false
    }
  },
  created() {
    this.position.x = Math.floor(this.order / this.grid.row) * this.cell.width + 10
    this.position.y = (Math.floor(this.order % this.grid.row) * this.cell.height) + 50
  },
  methods: {
    onMousedown(event) {
      console.log('onMousedown==========>' + event)
    },
    onContextmenu(event) {
      this.$store.commit('core/SET_CONTEXT_MENU', { x: event.clientX, y: event.clientY, type: 'icon', data: this.item.id })
    },
    onClick() {
      this.$store.dispatch('core/selectIcon', this.item.id)
    },
    onDblclick() {
      this.$store.dispatch('core/openApp', this.item.id)
    },
    onDragstart() {},
    onDragend() {}
  }
}
</script>

<style lang="less" rel="stylesheet/less">
.icon-item {
	position: absolute;
	/*border:1px dashed #fff;*/
	&.selected {
		.icon-body {
			/*border:2px solid rgba(43, 115, 199, 0.36);*/
			/*background:rgba(43, 115, 199, 0.2);*/
			background: rgba(255, 255, 255, 0.2);
			border: 1px solid #D8D8D8
		}
	}
	&.dragging {
		opacity: 0.6;
	}
	.icon-body:hover{
		background: rgba(255, 255, 255, 0.2);
		border: 1px solid #D8D8D8;
	}
	.icon-body {
		border:1px solid transparent;
		border-radius: 4px;
		position: absolute;
		top: 50%;
		left: 50%;
		padding-top: 10px;
		transform: translateX(-50%) translateY(-50%);
		width: 95px;
		.image {
			text-align: center;
			img {
				width: 36px;
				height: 36px;
			}
		}
		.icon {
			height: 46px;
			width: 46px;
			margin: 0 auto;
			background-size: 36px;
		}
		.text {
			min-height: 20px;
			line-height: 20px;
			text-align: center;
			font-size: 12px;
			/*margin:0 -10px; */
			overflow: hidden;
			word-wrap: break-word;
			word-break: break-all;
			text-overflow: ellipsis;
			padding-bottom: 5px;
			color: #FFFFFF;
		}
	}
}
</style>
