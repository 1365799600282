<template>
  <div class="manual-dir">
    <div class="video">
      <img src="@/assets/manual/dir1.png" alt="超算云桌面用户手册">
    </div>
    <div class="content">
      <!-- 常见问题 -->
      <div class="model-box">
        <div class="title">常见问题</div>
        <div class="dirs" @click="forgetPsd">
          <div class="dirs-circle" />
          <div>忘记密码后该怎么办？</div>
        </div>
        <div class="dirs" @click="problemCollection">
          <div class="dirs-circle" />
          <div>问题集锦</div>
        </div>
      </div>
      <!-- 使用指南 -->
      <div class="model-box">
        <div class="title">使用指南</div>
        <div class="dirs" @click="bindWechat">
          <div class="dirs-circle" />
          <div>账号如何绑定微信</div>
        </div>
        <div class="dirs" @click="officialAccount">
          <div class="dirs-circle" />
          <div>关注公众号，及时获取作业状态</div>
        </div>
      </div>
      <!-- 必学教程 -->
      <div class="model-box">
        <div class="title">必学教程</div>
        <div class="dirs" @click="applicationOpen('install')">
          <div class="dirs-circle" />
          <div>如何安装应用</div>
        </div>
        <div class="dirs" @click="applicationOpen('uninstall')">
          <div class="dirs-circle" />
          <div>如何卸载应用</div>
        </div>
      </div>
      <!-- 文件管理 -->
      <div class="model-box">
        <div class="title">文件管理</div>
        <div class="dirs" @click="fileHandle('fileTree')">
          <div class="dirs-circle" />
          <div>左侧树操作</div>
        </div>
        <div class="dirs" @click="fileHandle('fileList')">
          <div class="dirs-circle" />
          <div>文件列表</div>
        </div>
        <div class="dirs" @click="fileHandle('fileUpload')">
          <div class="dirs-circle" />
          <div>文件上传</div>
        </div>
        <div class="dirs" @click="fileHandle('addDir')">
          <div class="dirs-circle" />
          <div>新增目录</div>
        </div>
        <div class="dirs" @click="fileHandle('addFile')">
          <div class="dirs-circle" />
          <div>新建文件</div>
        </div>
        <div class="dirs" @click="fileHandle('deleteFile')">
          <div class="dirs-circle" />
          <div>删除文件</div>
        </div>
        <div class="dirs" @click="fileHandle('downloadFile')">
          <div class="dirs-circle" />
          <div>下载文件</div>
        </div>
        <div class="dirs" @click="fileHandle('compressFile')">
          <div class="dirs-circle" />
          <div>压缩文件</div>
        </div>
        <div class="dirs" @click="fileHandle('decompressFile')">
          <div class="dirs-circle" />
          <div>解压文件</div>
        </div>
        <div class="dirs" @click="fileHandle('recordFile')">
          <div class="dirs-circle" />
          <div>传输记录</div>
        </div>
        <div class="dirs" @click="fileHandle('otherFile')">
          <div class="dirs-circle" />
          <div>剪切、复制、粘贴、重命名</div>
        </div>
      </div>
      <!-- VCSCODE相关介绍 -->
      <div class="model-box">
        <div class="title">VCSCODE相关介绍</div>
        <div class="dirs" @click="vscodeHandle">
          <div class="dirs-circle" />
          <div>VCSCODE</div>
        </div>
      </div>
      <!-- 作业管理 -->
      <div class="model-box">
        <div class="title">作业管理</div>
        <div class="dirs" @click="desktopJob">
          <div class="dirs-circle" />
          <div>云桌面方式提交作业</div>
        </div>
        <div class="dirs" @click="terminalJob">
          <div class="dirs-circle" />
          <div>命令行方式提交作业</div>
        </div>
        <div class="dirs" @click="jobHandle">
          <div class="dirs-circle" />
          <div>作业管理相关操作</div>
        </div>
      </div>
      <!-- 账户信息 -->
      <div class="model-box">
        <div class="title">账户信息</div>
        <div class="dirs" @click="rechargeHandle">
          <div class="dirs-circle" />
          <div>账户充值</div>
        </div>
        <!-- <div class="dirs" @click="accountHandle">
          <div class="dirs-circle" />
          <div>账户详情</div>
        </div> -->
        <div class="dirs" @click="viewHandle">
          <div class="dirs-circle" />
          <div>账单概览</div>
        </div>
        <!-- <div class="dirs" @click="monthHandle">
          <div class="dirs-circle" />
          <div>月报</div>
        </div>
        <div class="dirs" @click="detailHandle">
          <div class="dirs-circle" />
          <div>详单查询</div>
        </div> -->
        <div class="dirs" @click="recordHandle">
          <div class="dirs-circle" />
          <div>充值记录</div>
        </div>
        <div class="dirs" @click="invoiceHandle">
          <div class="dirs-circle" />
          <div>发票管理</div>
        </div>
      </div>
      <!-- 具体应用介绍 -->
      <div class="model-box">
        <div class="title">具体应用介绍</div>
        <!-- <div class="dirs" @click="aiHandle">
          <div class="dirs-circle" />
          <div>AI</div>
        </div> -->
        <div class="dirs" @click="lammpsHandle">
          <div class="dirs-circle" />
          <div>Lammps</div>
        </div>
        <div class="dirs" @click="qeHandle">
          <div class="dirs-circle" />
          <div>Quantum ESPRESSO(QE)</div>
        </div>
        <div class="dirs" @click="cp2kHandle">
          <div class="dirs-circle" />
          <div>CP2K</div>
        </div>
        <div class="dirs" @click="vaspHandle">
          <div class="dirs-circle" />
          <div>vasp</div>
        </div>
        <div class="dirs" @click="abaqusHandle">
          <div class="dirs-circle" />
          <div>Abaqus</div>
        </div>
        <div class="dirs" @click="matlabHandle">
          <div class="dirs-circle" />
          <div>Matlab</div>
        </div>
        <div class="dirs" @click="comsolHandle">
          <div class="dirs-circle" />
          <div>Comsol</div>
        </div>
        <div class="dirs" @click="starccmHandle">
          <div class="dirs-circle" />
          <div>Star++ccm</div>
        </div>
        <div class="dirs" @click="msHandle">
          <div class="dirs-circle" />
          <div>Materials Studio(MS)</div>
        </div>
        <div class="dirs" @click="lsdynaHandle">
          <div class="dirs-circle" />
          <div>LS-DYNA</div>
        </div>
        <div class="dirs" @click="fluentHandle">
          <div class="dirs-circle" />
          <div>Fluent</div>
        </div>
        <div class="dirs" @click="mechanlcalHandle">
          <div class="dirs-circle" />
          <div>Ansys mechanlcal</div>
        </div>
        <div class="dirs" @click="gaussianHandle">
          <div class="dirs-circle" />
          <div>Gaussian</div>
        </div>
        <div class="dirs" @click="gromacsHandle">
          <div class="dirs-circle" />
          <div>Gromacs</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ManualDir',
  data() {
    return {}
  },
  computed: {
    usersManualId() {
      var usersManualId = null
      usersManualId = this.$store.state.core.usersManualId
      return usersManualId
    }
  },
  methods: {
    bindWechat() {
      const obj = {
        usersManualCount: null,
        id: 1
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    // 忘记密码
    forgetPsd() {
      const obj = {
        usersManualCount: null,
        id: 6
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    problemCollection() {
      const obj = {
        usersManualCount: null,
        id: 7
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    // 文件管理
    fileHandle(val) {
      sessionStorage.manualFileIdType = val
      const obj = {
        usersManualCount: null,
        id: 7100048350041969
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    // 作业管理
    // 云桌面方式提交
    desktopJob() {
      const obj = {
        usersManualCount: null,
        id: 3
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    terminalJob() {
      const obj = {
        usersManualCount: null,
        id: 5
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    jobHandle() {
      const obj = {
        usersManualCount: null,
        id: 3145878294710521
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    // vscode
    vscodeHandle() {
      const obj = {
        usersManualCount: null,
        id: 2108023696651968
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    // 账户信息
    rechargeHandle() {
      const obj = {
        usersManualCount: null,
        id: 2946147018625071
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    // 账户详情
    accountHandle() {
      const obj = {
        usersManualCount: null,
        id: 8889162036467441
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    // 详单概览
    viewHandle() {
      sessionStorage.userAccountManual = 'view'
      const obj = {
        usersManualCount: null,
        id: 0
      }
      this.$store.dispatch('core/usersManual', obj)
      this.$store.dispatch('core/userAccountManual', 1)
    },
    // 月报
    monthHandle() {
      sessionStorage.userAccountManual = 'month'
      const obj = {
        usersManualCount: null,
        id: -1
      }
      this.$store.dispatch('core/usersManual', obj)
      this.$store.dispatch('core/userAccountManual', 2)
    },
    // 详单查询
    detailHandle() {
      sessionStorage.userAccountManual = 'detail'
      const obj = {
        usersManualCount: null,
        id: -2
      }
      this.$store.dispatch('core/usersManual', obj)
      this.$store.dispatch('core/userAccountManual', 3)
    },
    // 充值记录
    recordHandle() {
      sessionStorage.userAccountManual = 'record'
      const obj = {
        usersManualCount: null,
        id: -3
      }
      this.$store.dispatch('core/usersManual', obj)
      this.$store.dispatch('core/userAccountManual', 4)
    },
    // 发票管理
    invoiceHandle() {
      sessionStorage.userAccountManual = 'invoice'
      const obj = {
        usersManualCount: null,
        id: -4
      }
      this.$store.dispatch('core/usersManual', obj)
      this.$store.dispatch('core/userAccountManual', 5)
    },
    // 具体应用介绍
    // ai
    aiHandle() {
      const obj = {
        usersManualCount: null,
        id: 8889162036467450
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    // lammps
    lammpsHandle() {
      const obj = {
        usersManualCount: null,
        id: 6153013083878343
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    qeHandle() {
      const obj = {
        usersManualCount: null,
        id: 1221482298789444
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    cp2kHandle() {
      const obj = {
        usersManualCount: null,
        id: 6258729541266953
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    vaspHandle() {
      const obj = {
        usersManualCount: null,
        id: 3350359504604818
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    abaqusHandle() {
      const obj = {
        usersManualCount: null,
        id: 8889162036467440
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    matlabHandle() {
      const obj = {
        usersManualCount: null,
        id: 8889162036467443
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    comsolHandle() {
      const obj = {
        usersManualCount: null,
        id: 8889162036467447
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    starccmHandle() {
      const obj = {
        usersManualCount: null,
        id: 2059033130546053
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    msHandle() {
      const obj = {
        usersManualCount: null,
        id: 8889162036467442
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    lsdynaHandle() {
      const obj = {
        usersManualCount: null,
        id: 3143428603900800
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    fluentHandle() {
      const obj = {
        usersManualCount: null,
        id: 3296931928216553
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    mechanlcalHandle() {
      const obj = {
        usersManualCount: null,
        id: 8889162036467449
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    gaussianHandle() {
      const obj = {
        usersManualCount: null,
        id: 7279991280277965
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    gromacsHandle() {
      const obj = {
        usersManualCount: null,
        id: 1382943853206252
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    // 关注公众号
    officialAccount() {
      const obj = {
        usersManualCount: null,
        id: 2
      }
      this.$store.dispatch('core/usersManual', obj)
    },
    // 应用管家3149252963594628
    applicationOpen(val) {
      sessionStorage.manualAppIdType = val
      const obj = {
        usersManualCount: null,
        id: 3149252963594628
      }
      this.$store.dispatch('core/usersManual', obj)
    }
  }
}
</script>

<style scoped lang="less">
.manual-dir{
  .video{
    margin-bottom: 4px;
  }
  .content{
    padding-left: 16px;
  }
}
</style>
