<template>
  <div class="manual-bind-wechat">
    <div class="video">
      <!-- <img src="@/assets/manual/bind-wechat.png" alt="超算云桌面用户手册"> -->
      <video src="@/assets/viod/bind.mp4" controls="controls" />

    </div>
    <div class="content">
      <div class="model-box not-home">
        <h1>方式1：通过平台绑定</h1>
        <div class="box-content">
          1：打开平台首页<span class="mask-color">(https://console.hpc.jengcloud.com)</span>
          <div class="mask-color space-tab">点击右上角的二维码图标</div>
          <div class="img-box">
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="超算云桌面用户手册">
            </viewer>
            <!-- <img src="@/assets/manual/wechat-code.png" alt="超算云桌面用户手册" @click="showImagePreview(1)"> -->
          </div>
          2：扫描二维码后
          <div class="box-content-bg">
            <div class="img-box">
              <viewer :images="imageUrl2" :options="options">
                <img :src="imageUrl2" alt="超算云桌面用户手册">
              </viewer>
              <!-- <img :src="imageUrl2" alt="超算云桌面用户手册" @click="showImagePreview(2)"> -->
            </div>
            <div class="box-content-bg-title">
              <div class="content-circle" />
              <div>如果没有账号，选择“我是新用户”</div>
            </div>
            <div class="space-tab">输入账号、密码、手机号、验证码，获取短信验证码后，输入短信验证码后提交，即同步完成新账号注册和绑定微信的操作</div>
            <div class="box-content-bg-title">
              <div class="content-circle" />
              <div>如果已有账号，选择“我是老用户”</div>
            </div>
            <div class="space-tab">输入账号绑定的手机号，获取短信验证码后，点“立即绑定”，即完成账号与微信的绑定</div>
          </div>
        </div>
      </div>
      <div class="model-box not-home">
        <h1>方式2：微信搜索山东正云信息科技有限公司关注公众号后绑定微信</h1>
        <div class="box-content">
          1：点击“点此绑定云桌面平台账号”
          <div class="img-box">
            <viewer :images="imageUrl3" :options="options">
              <img :src="imageUrl3" alt="超算云桌面用户手册">
            </viewer>
            <!-- <img src="@/assets/manual/wechat-bind.png" alt="超算云桌面用户手册" @click="showImagePreview(3)"> -->
          </div>
          2：输入云桌面账号绑定的手机号，获取验证码后，
          <div class="space-tab">
            点“立即绑定”，即完成账号与微信的绑定
          </div>
          <div class="img-box">
            <viewer :images="imageUrl4" :options="options">
              <img :src="imageUrl4" alt="超算云桌面用户手册">
            </viewer>
            <!-- <img src="@/assets/manual/wechat-msg.png" alt="超算云桌面用户手册" @click="showImagePreview(4)"> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/wechat-code-big.png'),
      imageUrl2: require('../assets/manual/wechat-user-big.png'),
      imageUrl3: require('../assets/manual/wechat-bind-big.png'),
      imageUrl4: require('../assets/manual/wechat-msg-big.png')
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.manual-bind-wechat{
  video{
    width: 100%;
    height: 100%;
  }
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
  .box-content-bg{
    .img-box{
      padding-left: 12px;
    }
    .space-tab{
      padding: 0 11px 8px 14px;
    }
  }
}
</style>
