import request from '@/utils/request'
import requestEhpc from '@/utils/requestEhpc'
const ehpc = '/service-ehpc-provider'
const appstore = '/service-appstore-provider/v1/appstore'
const invoiceUrl = '/service-invoice-provider/v1'
const billV3 = '/service-bill-provider/v3'
// 创建ehpc集群
export function addEhpcCluster(data) {
  return request({
    url: ehpc + '/ehpc/cluster',
    method: 'post',
    data
  })
}
// 删除ehpc集群
export function deleteEhpcCluster(clusterId) {
  return request({
    url: ehpc + '/ehpc/cluster?clusterId=' + clusterId,
    method: 'delete'
  })
}
// 根据uid查询ehpc集群
export function queryEhpcList(uId) {
  return request({
    url: ehpc + '/ehpc/cluster?uid=' + uId,
    method: 'get'
  })
}
// 创建集群
// export function addCluster() {
//   return request({
//     url: ehpc + '/ehpc/cluster',
//     method: 'post'
//   })
// }
// 获取集群信息
export function slurmInfo(clusterId) {
  return request({
    url: ehpc + '/v1/slurm/info?clusterId=' + clusterId,
    method: 'get'
  })
}
// 作业列表
export function slurmList(clusterId) {
  return request({
    url: ehpc + '/v1/slurm/job?clusterId=' + clusterId,
    method: 'get'
  })
}
// 队列列表
export function queueList(clusterId) {
  return request({
    url: ehpc + '/v1/slurm/queue?clusterId=' + clusterId,
    method: 'get'
  })
}
// 提交作业
export function submitSlurm(data, clusterId) {
  return request({
    url: ehpc + '/v1/slurm/job?clusterId=' + clusterId,
    method: 'post',
    data
  })
}
// 查询集群详情
export function slurmDetail(clusterId) {
  return request({
    url: ehpc + '/ehpc/cluster/' + clusterId,
    method: 'get'
  })
}
// 查询集群ssh连接方式
export function slurmConnect(clusterId) {
  return request({
    url: ehpc + '/ehpc/cluster/' + clusterId + '/ssh',
    method: 'get'
  })
}
// 获取用户账户账户信息
export function getAccountMessage() {
  return requestEhpc({
    url: billV3 + '/bill/indexPageData',
    method: 'get'
  })
}
// 根据类型查询消费详单
export function getConsumptionDetail(data) {
  return requestEhpc({
    url: billV3 + '/bill/bills/consumptionDetail',
    method: 'get',
    params: data
  })
}
// 多域根据账号查询消费账单
// 获取用户账户账户信息
export function getConsumeNote(data) {
  return requestEhpc({
    url: billV3 + '/bill/bills/consumption/accountId',
    method: 'get',
    params: data
  })
}
// 充值记录
export function getRechargeNote(data) {
  return requestEhpc({
    url: billV3 + '/bill/bills/recharge/accountId',
    method: 'get',
    params: data
  })
}
// 根据月份查询充值记录
export function getRechargeNoteByMonth(data) {
  return requestEhpc({
    url: billV3 + '/bill/monthRecharge',
    method: 'get',
    params: data
  })
}
// 根据月份查询消费记录
export function getConsumptionNoteByMonth(data) {
  return requestEhpc({
    url: billV3 + '/bill/monthConsumption',
    method: 'get',
    params: data
  })
}
// 获取消费记录详情  T
// export function getConsumeNote(payType, pagenum, pagesize, sortBy, orderBy, keyWord) {
//   return requestEhpc({
//     url: billV3 + '/bill/bills/accountIdAndPayType',
//     // url: baseUrl + '/billDetail',
//     method: 'get',
//     params: {
//       payType,
//       pagenum,
//       pagesize,
//       keyWord,
//       sortBy,
//       orderBy
//     }
//   })
// }
// 获取自定义参数
export function queryCustomParam(id) {
  return requestEhpc({
    url: appstore + '/description?id=' + id,
    method: 'get'
  })
}

// 开具发票
export function applyInvoice(data) {
  return requestEhpc({
    url: invoiceUrl + '/invoice/',
    method: 'post',
    data
  })
}

// 查询开票额度
export function getInvoicingLimit() {
  return requestEhpc({
    url: invoiceUrl + '/invoice/account',
    method: 'get'
  })
}

// 管理发票抬头
export function manageInvoiceRiseHistory() {
  return requestEhpc({
    url: invoiceUrl + '/invoiceRise/history',
    method: 'get'
  })
}

// 修改/添加发票抬头
export function editInvoiceHeader(data) {
  return requestEhpc({
    url: invoiceUrl + '/invoiceRise/',
    method: 'post',
    data
  })
}

// 查询开票记录
export function queryInvoiceHistory(pagenum, pagesize, sortBy = '', orderBy = '') {
  return requestEhpc({
    url: invoiceUrl + '/invoice/history',
    method: 'get',
    params: {
      pagenum,
      pagesize,
      sortBy,
      orderBy
    }
  })
}
