import { mapState } from 'vuex'
import store from '@/store'
import { ElMessageBox, ElMessage, dividerProps } from 'element-plus'
export const downloadProgress = {
  name: 'downLoadProgress',
  // computed: {
  //   ...mapState({
  //     'progressList': state => state.downLoadProgress.progressList
  //   })
  // },
  data() {
    return {
      notify: {}, // 用来维护下载文件进度弹框对象
      uploadFilePauseUidList: [],
      cancelFileUidList: []
    }
  },
  watch: { // 监听进度列表
    // progressList: {
    //   handler(n) {
    //     const data = JSON.parse(JSON.stringify(n))
    //     data.forEach(item => {
    //       const domList = [...document.getElementsByClassName(item.time)]
    //       if (domList.find(i => i.className == item.time)) { // 如果页面已经有该进度对象的弹框，则更新它的进度progress
    //         domList.find(i => i.className == item.time).children[1].childNodes[0].childNodes[0].style.width = item.progress + '%'
    //         domList.find(i => i.className == item.time).children[1].childNodes[1].innerHTML = item.progress + '%'
    //         // domList.find(i => i.className == item.time).innerHTML = item.name + '上传进度：' + item.progress + '%'
    //       } else {
    //         if (item.progress === null) { // 此处容错处理，如果后端传输文件流报错，删除当前进度对象
    //           this.$store.commit('downLoadProgress/DEL_PROGRESS', item.time)
    //           return
    //         }
    //         const newDiv = document.createElement('div')
    //         newDiv.className = item.time
    //         newDiv.style.padding = '16px 0 16px 24px'
    //         newDiv.style.borderBottom = '1px solid #E7E7E7'
    //         const detailDiv = document.createElement('div')
    //         detailDiv.className = 'records-detail'
    //         detailDiv.className = 'flex-normal-style'
    //         newDiv.append(detailDiv)
    //         // 进度条
    //         const progress = document.createElement('div')
    //         progress.className = 'progress-bar'
    //         newDiv.append(progress)
    //         // 进度条外部
    //         const progressOuter = document.createElement('div')
    //         progressOuter.className = 'progress-bar-outer'
    //         progress.append(progressOuter)
    //         // 进度条文字
    //         const progressText = document.createElement('div')
    //         progressText.className = 'progress-bar-text'
    //         progressText.innerHTML = item.progress + '%'
    //         progress.append(progressText)
    //         // 进度条内部
    //         const progressInner = document.createElement('div')
    //         progressInner.className = 'progress-bar-inner'
    //         progressInner.style.width = item.progress + '%'
    //         progressOuter.append(progressInner)
    //         const childNameDiv = document.createElement('div')
    //         const childTimeDiv = document.createElement('div')
    //         childNameDiv.className = 'records-name'
    //         childTimeDiv.className = 'records-time'
    //         childNameDiv.innerHTML = item.name
    //         childTimeDiv.innerHTML = item.update_time
    //         detailDiv.append(childNameDiv)
    //         detailDiv.append(childTimeDiv)
    //         // 暂停取消
    //         const operateDiv = document.createElement('div')
    //         operateDiv.className = 'progress-operate'
    //         newDiv.append(operateDiv)
    //         const pauseIcon = document.createElement('img')
    //         pauseIcon.src = 'https://pic.imgdb.cn/item/641bad6ba682492fccd461bf.png'
    //         pauseIcon.style.marginRight = '12px'
    //         // 继续
    //         const continueIcon = document.createElement('img')
    //         continueIcon.src = 'https://pic.imgdb.cn/item/641814b2a682492fccc553a8.png'
    //         continueIcon.style.marginRight = '12px'
    //         continueIcon.style.display = 'none'
    //         // 继续点击事件
    //         const that = this
    //         pauseIcon.onclick = function() {
    //           that.uploadFilePauseUidList.push(item.time)
    //           localStorage.uploadFilePauseUidList = JSON.stringify(that.uploadFilePauseUidList)
    //           pauseIcon.style.display = 'none'
    //           continueIcon.style.display = 'block'
    //         }
    //         // 继续点击事件
    //         continueIcon.onclick = function() {
    //           continueIcon.style.display = 'none'
    //           pauseIcon.style.display = 'block'
    //           that.uploadFilePauseUidList.forEach((e, i) => {
    //             if (e === item.time) {
    //               that.uploadFilePauseUidList.splice(i, 1)
    //             }
    //           })
    //           localStorage.uploadFilePauseUidList = JSON.stringify(that.uploadFilePauseUidList)
    //         }
    //         const cancelIcon = document.createElement('img')
    //         cancelIcon.src = 'https://pic.imgdb.cn/item/641814b0a682492fccc54e4d.png'
    //         operateDiv.append(pauseIcon)
    //         operateDiv.append(continueIcon)
    //         operateDiv.append(cancelIcon)
    //         // 取消上传点击后
    //         cancelIcon.onclick = function() {
    //           that.cancelFileUidList.push(item.time)
    //           localStorage.cancelFileUidList = JSON.stringify(that.cancelFileUidList)
    //           document.getElementById('upLoadProgress').removeChild(document.getElementsByClassName(item.time)[0])
    //           // delete this.notify[item.time] 上面的close()事件是异步的，这里直接删除会报错，利用setTimeout，将该操作加入异步队列
    //           setTimeout(() => {
    //             delete that.notify[item.time]
    //             that.cancelFileUidList.forEach((e, i) => {
    //               if (e === item.time) {
    //                 that.cancelFileUidList.splice(i, 1)
    //               }
    //             })
    //           }, 1000)
    //           that.$store.commit('downLoadProgress/DEL_PROGRESS', item.time)// 删除downLoadProgress中state的progressList中的进度对象
    //         }
    //         this.notify[item.time] = document.getElementById('upLoadProgress').append(newDiv)
    //         // newDiv.innerHTML = item.name + '上传进度：' + item.progress + '%'
    //       }
    //       if (item.progress == 100) { // 如果进度到了100%，关闭该弹框，并删除notify中维护的弹框对象
    //         document.getElementById('upLoadProgress').removeChild(document.getElementsByClassName(item.time)[0])
    //         // delete this.notify[item.time] 上面的close()事件是异步的，这里直接删除会报错，利用setTimeout，将该操作加入异步队列
    //         setTimeout(() => {
    //           delete this.notify[item.time]
    //         }, 1000)
    //         this.$store.commit('downLoadProgress/DEL_PROGRESS', item.time)// 删除downLoadProgress中state的progressList中的进度对象
    //       }
    //     })
    //   },
    //   deep: true
    // }
  }
}
