<template>
  <div class="manual-recharge-record">
    <div class="video">
      <img src="@/assets/manual/video12.png" alt="超算云桌面用户手册">
    </div>
    <div class="content">
      <div class="model-box not-home">
        <h1>充值记录</h1>
        <div class="box-content">
          <div>
            1、点击右上角头像
          </div>
          <div>
            2、点击“账户详情”
          </div>
          <div>
            3、选择“充值记录”菜单，支持根据多个条件进行查询：可以根据交易流水号模糊搜索，选择交易时间段查询，还可以勾选充值渠道进行筛选。
          </div>
          <div class="img-box">
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/record1-big.png')
    }
  }
}
</script>

<style scoped lang="less">
.manual-recharge-record{
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
}
</style>
