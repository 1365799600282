<template>
  <div class="manual-invoice">
    <div class="video">
      <img src="@/assets/manual/video8.png" alt="超算云桌面用户手册">
    </div>
    <div class="content">
      <div class="model-box not-home">
        <h1>发票管理</h1>
        <div class="box-content">
          1、点击右上角头像
          <div>
            2、点击“账户详情”
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/psd1.png" alt="超算云桌面用户手册" @click="showImagePreview(1)"> -->
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="超算云桌面用户手册">
            </viewer>
          </div>
          3、选择“发票管理”菜单
          <div class="box-content-bg">
            <div class="box-content-bg-title">
              <div class="content-circle" />
              <div>已开票历史</div>
            </div>
            <div class="space-tab">
              根据开票提交时间倒序展示的，可以查看发票ID，开票金额、类型、抬头、提交时间等发票信息。
            </div>
            <div class="img-box">
              <viewer :images="imageUrl2" :options="options">
                <img :src="imageUrl2" alt="超算云桌面用户手册">
              </viewer>
              <!-- <img src="@/assets/manual/psd2.png" alt="超算云桌面用户手册" @click="showImagePreview(2)"> -->
            </div>
            <div class="box-content-bg-title">
              <div class="content-circle" />
              <div>管理发票抬头</div>
            </div>
            <div class="space-tab">
              可以新增或修改企业或个人的发票抬头信息，用于开具发票时自动填充
            </div>
            <div class="img-box">
              <viewer :images="imageUrl3" :options="options">
                <img :src="imageUrl3" alt="超算云桌面用户手册">
              </viewer>
            </div>
            <div class="box-content-bg-title">
              <div class="content-circle" />
              <div>申请开票</div>
            </div>
            <div class="space-tab">
              可开具企业或个人的发票，开票金额不能大于账户实际充值金额
            </div>
            <div class="img-box">
              <viewer :images="imageUrl4" :options="options">
                <img :src="imageUrl4" alt="超算云桌面用户手册">
              </viewer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/invoice1-big.png'),
      imageUrl2: require('../assets/manual/invoice2-big.png'),
      imageUrl3: require('../assets/manual/invoice3-big.png'),
      imageUrl4: require('../assets/manual/invoice4-big.png')
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.manual-invoice{
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
  .box-content-bg{
    padding-right: 12px;
    .img-box{
      padding-left: 12px;
    }
    .space-tab{
      padding: 0 11px 8px 14px;
    }
  }
}
</style>
