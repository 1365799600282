<template>
  <div class="manual-problem-collection">
    <div class="content">
      <div class="flex-style">
        <svg-icon icon-class="star" />
        <h1>
          从哪里查看剩余费用？如果没有费用了，已提交运行的作业会不会终止运行？
        </h1>
      </div>
      <div class="space-tab box-content">
        答：云桌面有一个账户详情，账户详情里面可以查看账号下费用，还可以查询具体作业情况。如果没有费用了，已提交的作业不会终止运行，直到作业计算完，此外在欠费后，提交新作业会失败，无法运行。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    usersManualId() {
      var usersManualId = null
      usersManualId = this.$store.state.core.usersManualId
      return usersManualId
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.manual-problem-collection{
  padding-right: 45px;
  .svg-icon{
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 6px;
  }
}
</style>
