<template>
  <div class="manual-official-account">
    <div class="video">
      <img src="@/assets/manual/bind-wechat.png" alt="超算云桌面用户手册">
    </div>
    <div class="content">
      <div class="model-box not-home">
        <h1>关注公众号，作业情况将通过公众号推送</h1>
        <div class="box-content">
          方式1：登录平台后扫码关注
          <div class="img-box">
            <!-- <img src="@/assets/manual/official-account1.png" alt="超算云桌面用户手册" @click="showImagePreview(1)"> -->
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="超算云桌面用户手册">
            </viewer>
          </div>
          方式2：微信搜索山东正云信息科技有限公司
          <div class="img-box">
            <!-- <img src="@/assets/manual/official-account2.png" alt="超算云桌面用户手册" @click="showImagePreview(2)"> -->
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl2" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/official-account1-big.png'),
      imageUrl2: require('../assets/manual/official-account2-big.png')
    }
  },
  methods: {
    showImagePreview(val) {
      if (document.body.getElementsByClassName('previewContainer').length !== 0) {
        document.body.removeChild(document.body.getElementsByClassName('previewContainer')[0])
      }
      const image = new Image()
      // image.src = this.imageUrl
      if (val === 1) {
        image.src = this.imageUrl1
      } else {
        image.src = this.imageUrl2
      }
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div')
        previewContainer.className = 'previewContainer'
        previewContainer.style.position = 'fixed'
        previewContainer.style.top = 0
        previewContainer.style.bottom = 0
        previewContainer.style.left = 0
        previewContainer.style.right = 0
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContainer.style.display = 'flex'
        // previewContainer.style.justifyContent = 'center'
        previewContainer.style.alignItems = 'center'
        document.body.appendChild(previewContainer)
        // 在弹出层中添加图片
        const previewImage = document.createElement('img')
        // previewImage.src = this.imageUrl
        if (val == 1) {
          previewImage.src = this.imageUrl1
        } else {
          previewImage.src = this.imageUrl2
        }
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewContainer.appendChild(previewImage)
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer)
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.manual-official-account{
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
}
</style>
