<template>
  <div id="manualAi" class="manual-ai">
    <div class="video">
      <img src="@/assets/manual/video13.png" alt="超算云桌面用户手册">
    </div>
    <div>
      <h1 style="padding-top:24px;">
        用户在应用商城下载【AI】应用至桌面，双击应用图标可进入下图操作页面。
      </h1>
      <div class="img-box">
        <!-- <img src="@/assets/manual/dir.png" alt="超算云桌面用户手册" @click="showImagePreview(1)"> -->
        <viewer :images="imageUrl1" :options="options">
          <img :src="imageUrl1" style="width:347px;height:187px;" alt="超算云桌面用户手册">
        </viewer>
      </div>
    </div>
    <div class="content" style="padding-left:11px;">
      <div class="model-box not-home">
        <h1>1、项目组管理</h1>
        <div class="box-content">
          <div>
            进入AI操作页面后，默认为【个人项目】，若用户想要多人进行共同AI操作，可单击左上方【项目组管理】，进行【查看】和【创建】项目组。
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/dir.png" alt="超算云桌面用户手册" @click="showImagePreview(1)"> -->
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl2" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div class="model-box not-home">
        <h1>2、训练管理</h1>
        <div class="box-content">
          <div>
            进入AI操作页面后，默认为【个人项目】，若用户想要多人进行共同AI操作，可单击左上方【项目组管理】，进行【查看】和【创建】项目组。
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/file1.png" alt="超算云桌面用户手册" @click="showImagePreview(2)"> -->
            <viewer :images="imageUrl3" :options="options">
              <img :src="imageUrl3" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div class="model-box not-home">
        <h1>3、开发环境</h1>
        <div class="box-content">
          <div>
            用户在首页点击右上角【开发环境】可创建环境，首先在开发环境页面点击左上方选择用户所需的项目组，在界面右侧填入创建开发环境所对应的参数：环境信息、资源配置、辅助配置以及高级设置，点击右上方【创建】按钮，即可在页面左侧查看已创建的开发环境。
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/file2.png" alt="超算云桌面用户手册" @click="showImagePreview(3)"> -->
            <viewer :images="imageUrl4" :options="options">
              <img :src="imageUrl4" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div class="model-box not-home">
        <h1>4、镜像仓库</h1>
        <div class="box-content">
          <div>
            用户在首页点击右上角【镜像仓库】，可查询当前应用的所有镜像以及对应的相应信息。
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/file3.png" alt="超算云桌面用户手册" @click="showImagePreview(4)"> -->
            <viewer :images="imageUrl5" :options="options">
              <img :src="imageUrl5" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/ai1-bg.png'),
      imageUrl2: require('../assets/manual/ai2-bg.png'),
      imageUrl3: require('../assets/manual/ai3-bg.png'),
      imageUrl4: require('../assets/manual/ai4-bg.png'),
      imageUrl5: require('../assets/manual/ai5-bg.png')
    }
  },
  methods: {
    showImagePreview(val) {
      if (document.body.getElementsByClassName('previewContainer').length !== 0) {
        document.body.removeChild(document.body.getElementsByClassName('previewContainer')[0])
      }
      const image = new Image()
      // image.src = this.imageUrl
      if (val === 1) {
        image.src = this.imageUrl1
      } else if (val === 2) {
        image.src = this.imageUrl2
      } else if (val === 3) {
        image.src = this.imageUrl3
      } else if (val === 4) {
        image.src = this.imageUrl4
      } else {
        image.src = this.imageUrl5
      }
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div')
        previewContainer.className = 'previewContainer'
        previewContainer.style.position = 'fixed'
        previewContainer.style.top = 0
        previewContainer.style.bottom = 0
        previewContainer.style.left = 0
        previewContainer.style.right = 0
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContainer.style.display = 'flex'
        // previewContainer.style.justifyContent = 'center'
        previewContainer.style.alignItems = 'center'
        document.body.appendChild(previewContainer)
        // 在弹出层中添加图片
        const previewImage = document.createElement('img')
        // previewImage.src = this.imageUrl
        if (val == 1) {
          previewImage.src = this.imageUrl1
        } else if (val === 2) {
          previewImage.src = this.imageUrl2
        } else if (val === 3) {
          previewImage.src = this.imageUrl3
        } else if (val === 4) {
          previewImage.src = this.imageUrl4
        } else {
          previewImage.src = this.imageUrl5
        }
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewContainer.appendChild(previewImage)
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer)
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.manual-ai{
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
}
</style>
