<template>
  <div
    v-show="!setting.min"
    id="window"
    class="window animated"
    :class="{ focus: setting.focus, animating: animating, maximized: maximized, bounceIn: animatedIn, zoomOut: animatedOut }"
    :style="{ top: position.y + 'px', left: position.x + 'px', width: width + 'px', height: height + 'px', 'z-index': setting.index }"
    @mousedown="onFocus(setting.id)"
  >
    <header class="window-title" @mousedown.self="onMousedown($event)" @dblclick.self="onMax()">
      <!-- <div class="icon" :class="setting.icon"></div> -->
      <img :src="setting.icon" alt="">
      {{ setting.title }}
      <div class="window-control">
        <div class="svg-box" @mousedown="onUsersManual(setting.id)">
          <svg-icon icon-class="manual" />
        </div>
        <div class="svg-box" @mousedown="onMin(setting.id)">
          <svg-icon icon-class="mini" />
        </div>
        <div class="svg-box" @mousedown="onMax()">
          <svg-icon v-if="maximized" icon-class="recovery" />
          <svg-icon v-else icon-class="max" />
        </div>
        <div class="svg-box" @mousedown="onClose(setting.id, setting.linkId)">
          <svg-icon icon-class="close" />
        </div>
        <!-- <span class="button min"></span>
				<span class="button max"></span>
				<span class="button close" @mousedown="onClose(setting.id)"></span> -->
      </div>
    </header>
    <div :ref="'window_' + setting.id" class="window-body">
      <keep-alive><component :is="subComponent" v-model:window="window" /></keep-alive>
    </div>
    <div v-show="overlayShow" class="resize-overlay" />
    <div v-for="(value, index) in resizeSide" v-show="resizable" :key="index" class="resize-side" :class="value" @mousedown.self="onResize(value)" />
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent, markRaw } from 'vue'
import { updateWindowSize } from '@/api/application'
export default defineComponent({
  name: 'Window',
  props: {
    setting: {
      id: String,
      focus: Boolean,
      hidden: Boolean,
      index: Number,
      width: Number,
      height: Number
    }
  },
  data() {
    return {
      usersManualCount: 0,
      subComponent: {},
      timer: {},
      drag: false,
      resize: false,
      width: 200,
      height: 400,
      maximized: false,
      animating: false,
      animatedIn: false,
      animatedOut: false,
      resizable: true,
      position: {
        x: 0,
        y: 0
      },
      oldposition: {},
      overlayShow: false,
      resizeSide: ['side-top', 'side-bottom', 'side-left', 'side-right', 'angle-top-left', 'angle-top-right', 'angle-bottom-left', 'angle-bottom-right']
    }
  },
  computed: {
    window() {
      return { width: this.width, height: this.height, id: this.setting.id }
    }
  },
  created() {
    const w = document.body.clientWidth
    var h = 0
    if (document.body.clientWidth > document.body.clientHeight) {
      h = document.body.clientHeight
    } else {
      h = document.body.clientWidth
    }
    this.width = this.setting.width > 0 ? this.setting.width : w / 1.5
    this.height = this.setting.height > 0 ? this.setting.height : h / 1.2
    // this.position.x = w / 2 - this.width / 2;
    // this.position.y = (h - this.height) / 2;
    this.position.x = this.setting.x
    this.position.y = this.setting.y
    this.animatedIn = true
    // console.log("created====>" +  this.setting.page)
    this.subComponent = markRaw(defineAsyncComponent(() => import('../applications/' + this.setting.page + '.vue')))
  },
  mounted() {
    // console.log("mounted====>")
    this.animatedIn = true
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      clearTimeout(this.timer)
      this.animatedIn = false
    }, 400)
  },
  methods: {
    // 用户手册
    onUsersManual(id) {
      this.usersManualCount++
      const obj = {
        usersManualCount: this.usersManualCount,
        id: id
      }
      this.$store.dispatch('core/usersManual', obj)
      if (id === 3149252963594628) {
        sessionStorage.manualAppIdType = 'install'
      }
      if (id === 7100048350041969) {
        sessionStorage.manualFileIdType = 'file'
      }
      if (id === 8889162036467441) {
        if (sessionStorage.userAccountManual === 'view') {
          const obj = {
            usersManualCount: this.usersManualCount,
            id: 0
          }
          this.$store.dispatch('core/usersManual', obj)
        } else if (sessionStorage.userAccountManual === 'detail') {
          const obj = {
            usersManualCount: this.usersManualCount,
            id: 0
          }
          this.$store.dispatch('core/usersManual', obj)
        } else if (sessionStorage.userAccountManual === 'record') {
          const obj = {
            usersManualCount: this.usersManualCount,
            id: -3
          }
          this.$store.dispatch('core/usersManual', obj)
        } else if (sessionStorage.userAccountManual === 'invoice') {
          const obj = {
            usersManualCount: this.usersManualCount,
            id: -4
          }
          this.$store.dispatch('core/usersManual', obj)
        }
      }
    },
    onFocus(id) {
      this.$store.dispatch('core/focus', id)
    },
    onClose(id, linkId) {
    //   if (id === 6129392494665433) {
    //     localStorage.clusterId = ''
    //     localStorage.activeCluster = null
    //   }
      if (id !== 6372403323111393) {
        const data = {
          linkId: linkId,
          height: this.height,
          width: this.width,
          x: this.position.x,
          y: this.position.y
        }
        updateWindowSize(data).then((response) => {
          if (response.meta.status === 200) {
            if (linkId != null) {
              this.$store.state.core.applications.forEach(e => {
                if (e.id == id) {
                  e.width = this.width
                  e.height = this.height
                  e.x = this.position.x
                  e.y = this.position.y
                }
              })
            }
          }
        })
      }

      this.animatedOut = true
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        clearTimeout(this.timer)
        this.$store.dispatch('core/close', id)
      }, 300)
    },
    onMin(id) {
      this.$store.dispatch('core/minOrShow', id)
    },
    onMax() {
      if (this.maximized && this.oldPosition) {
        this.animating = true
        this.resizable = true
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.maximized = false
          this.position.y = this.oldPosition.y
          this.position.x = this.oldPosition.x
          this.height = this.oldPosition.height
          this.width = this.oldPosition.width
          this.oldPosition = null
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            this.animating = false
          }, 500)
        })
      } else {
        this.animating = true
        this.resizable = false
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.maximized = true
          this.oldPosition = {
            y: this.position.y,
            x: this.position.x,
            height: this.height,
            width: this.width
          }
          this.position.y = 36
          this.position.x = 0
          this.height = document.body.clientHeight * 0.975
          this.width = document.body.clientWidth
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            this.animating = false
          }, 500)
        })
      }
    },
    onResize(direction) {
      this.resize = true
      this.overlayShow = true
      document.onmousemove = event => {
        if (this.resize) {
          let x = event.clientX
          let y = event.clientY
          const mx = document.body.clientWidth
          const my = document.body.clientHeight
          x = x >= 0 ? (x >= mx ? mx : x) : 0
          y = y >= 0 ? (y >= my ? my : y) : 0
          this.position.x = parseFloat(this.position.x)
          this.position.y = parseFloat(this.position.y)
          x = parseFloat(x)
          y = parseFloat(y)
          this.width = parseFloat(this.width)
          this.height = parseFloat(this.height)
          if (direction.indexOf('top') >= 0) {
            if (this.position.y <= 36) {
              this.position.y = 36
            } else {
              this.height = this.height + (this.position.y - y)
              this.position.y = y
            }
          }
          if (direction.indexOf('left') >= 0) {
            this.width = this.width + (this.position.x - x)
            this.position.x = x
          }
          if (direction.indexOf('bottom') >= 0) {
            this.height = this.height + (y - (this.position.y + this.height))
          }
          if (direction.indexOf('right') >= 0) {
            this.width = this.width + (x - (this.position.x + this.width))
          }
          this.$nextTick(() => {
            localStorage.width = this.width
            localStorage.height = this.height
            localStorage.x = this.position.x
            localStorage.y = this.position.y
          })
        }
      }
      document.onmouseup = () => {
        this.resize = false
        this.overlayShow = false
      }
    },
    onMousedown(e) {
      const w = e.clientX - this.position.x
      const h = e.clientY - this.position.y
      if (!this.maximized) {
        this.drag = true
        document.onmousemove = e => {
          if (this.drag) {
            // let mx = document.body.clientWidth;
            // let my = document.body.clientHeight * 0.95;
            this.position.x = e.clientX - w
            this.position.y = e.clientY - h
            if (this.position.y < 36) {
              this.position.y = 36
            }
            if (this.position.y > document.body.clientHeight * 0.99) {
              this.position.y = document.body.clientHeight * 0.99
            }
            // this.position.x = this.position.x <= 0 ? 0 : this.position.x + this.width >= mx ? mx - this.width : this.position.x;
            // this.position.y = this.position.y <= 0 ? 0 : this.position.y + this.height >= my ? my - this.height : this.position.y;
          }
        }
        document.onmouseup = () => {
          // console.log("e===>" + this.drag)
          this.drag = false
        }
        this.$nextTick(() => {
          localStorage.width = this.width
          localStorage.height = this.height
          localStorage.x = this.position.x
          localStorage.y = this.position.y
        })
      }
    }
  }
})
</script>

<style lang="less" rel="stylesheet/less">
@import '../global';

.window {
	position: absolute;
	border-radius: 20px;
	overflow: hidden;
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.4);

	&.animating {
		transition: all ease 0.5s;
	}
	.window-body {
		// padding: 15px 20px;
		background: #fff;
		right: 0;
		bottom: 0;
		left: 0;
		position: absolute;
		top: 40px;
		width: 100%;
		border-top: 1px solid #d8d8d8;
    overflow: hidden;
	}

	.window-title {
		width: 100%;
		box-sizing: border-box;
		background: #F5F5F5;
		padding-left: 50px;
		// text-align: center;
		font-weight: bold;
		color: #aeafb3;
		font-size: 16px;
		line-height: 40px;
		height: 40px;
		cursor: default;
		color: #aaa;
		img{
			width: 25px;
			height: 25px;
			position: absolute;
			top: 8px;
			left: 15px;
		}
		.icon {
			position: absolute;
			top: 8px;
			left: 15px;
			height: 25px;
			width: 25px;
			background-size: cover;
		}
	}

	&.focus {
		box-shadow: 0 10px 35px rgba(0, 0, 0, 0.6);
		.window-title {
			//background: #f5f8ff;
			background: #F5F5F5;
			color: #333;
		}
		z-index: 999;
	}

	&.maximized {
		.window-control .button.max {
			@margin: 5px;
			@diff: 2px;
			@w: 8px;
			@b: 1px;
			@top: 0px;
			@left: 0px;

			&:before {
				box-sizing: border-box;
				content: '';
				display: block;
				position: absolute;
				height: @w;
				width: @w;
				left: ((40px - 2 * @margin - @w) ./ 2)- @diff + @left;
				top: ((40px - 2 * @margin - @w) ./ 2) + @diff + @top;
				border: @b solid #aeafb3;
				border-top-width: 2 * @b;
			}

			&:after {
				box-sizing: border-box;
				content: '';
				display: block;
				position: absolute;
				height: @w;
				width: @w;
				left: ((40px - 2 * @margin - @w) ./ 2) + @diff + @left;
				top: ((40px - 2 * @margin - @w) ./ 2)- @diff + @top;
				border: @b solid #aeafb3;
				border-top-width: 2 * @b;
			}
		}
	}

	.window-control {
		@margin: 5px;
		position: absolute;
		right: 0;
		top: 0;
		height: 40px;
		display: flex;
		.svg-box{
			padding: 5px 14px;
		}
		.svg-box:hover{
			background-color: #d8dae2;
		}
		// .svg-icon{
		// 	margin-right: 28px;
		// }
		.button {
			display: block;
			position: relative;
			width: 40px - 2 * @margin;
			height: 40px - 2 * @margin;
			margin: @margin @margin 0 0;
			float: left;
			border-radius: 3px;
		}

		.min {
			// background: #81c2d0;
			&:before {
				box-sizing: border-box;
				@w: 12px;
				@h: 2px;
				content: '';
				display: block;
				position: absolute;
				height: @h;
				width: @w;
				left: (40px - 2 * @margin - @w) ./ 2;
				bottom: ((40px - 2 * @margin - @h) ./ 2) - 4px;
				background: #aeafb3;
			}
		}
		.max {
			// background: #659a65;
			&:before {
				box-sizing: border-box;
				@w: 12px;
				@b: 1px;
				content: '';
				display: block;
				position: absolute;
				height: @w;
				width: @w;
				left: ((40px - 2 * @margin - @w) / 2);
				top: ((40px - 2 * @margin - @w) / 2);
				border: @b solid #aeafb3;
				border-top-width: 2 * @b;
			}
		}

		.Rotate(@deg) {
			transform: rotate(@deg);
			-webkit-transform: rotate(@deg);
			-moz-transform: rotate(@deg);
		}

		.close {
			&:before,
			&:after {
				@w: 14px;
				@h: 2px;
				content: '';
				display: block;
				position: absolute;
				height: @h;
				width: @w;
				left: ((40px - 2 * @margin - @w) / 2);
				top: ((40px - 2 * @margin - @h) / 2);
				background: #aeafb3;
				.Rotate(45deg);
			}
			&:after {
				.Rotate(-45deg);
			}
			// background: #d26262;
		}
	}

	@reactionWidth: 4px;
	.resize-side {
		position: absolute;
	}
	.resize-overlay {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
	.side-right {
		right: 0;
		bottom: 0;
		height: 100%;
		width: @reactionWidth;
		cursor: e-resize;
	}
	.side-bottom {
		right: 0;
		bottom: 0;
		width: 100%;
		height: @reactionWidth;
		cursor: s-resize;
	}
	.side-left {
		left: 0;
		top: 0;
		height: 100%;
		width: @reactionWidth;
		cursor: w-resize;
	}
	.side-top {
		left: 0;
		top: 0;
		width: 100%;
		height: @reactionWidth;
		cursor: n-resize;
	}
	.angle-bottom-right {
		right: 0;
		bottom: 0;
		width: @reactionWidth*2;
		height: @reactionWidth*2;
		cursor: se-resize;
	}
	.angle-bottom-left {
		left: 0;
		bottom: 0;
		width: @reactionWidth*2;
		height: @reactionWidth*2;
		cursor: sw-resize;
	}
	.angle-top-right {
		top: 0;
		right: 0;
		width: @reactionWidth*2;
		height: @reactionWidth*2;
		cursor: ne-resize;
	}
	.angle-top-left {
		top: 0;
		left: 0;
		width: @reactionWidth*2;
		height: @reactionWidth*2;
		cursor: nw-resize;
	}
}
</style>
