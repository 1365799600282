<template>
  <div class="manual-gaussian">
    <div class="video">
      <img src="@/assets/manual/app13.png" alt="超算云桌面用户手册">
    </div>
    <div class="content">
      <div id="manualInstall" class="model-box not-home">
        <h1>Gaussian使用教程</h1>
        <div class="box-content">
          1、从桌面选择Gaussian双击，或者右键选择运行进入运行参数修改界面
          <div class="img-box">
            <!-- <img src="@/assets/manual/gaussian1.png" alt="超算云桌面用户手册" @click="showImagePreview(1)"> -->
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="超算云桌面用户手册">
            </viewer>
          </div>
          <div>
            2、设置提交参数并运行
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/gaussian2.png" alt="超算云桌面用户手册" @click="showImagePreview(2)"> -->
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl2" alt="超算云桌面用户手册">
            </viewer>
          </div>
          <div class="space-tab">
            参数解析：
            <div>
              1.作业名称：gstest,这个作业名称是用户自行定义的，对具体运行无影响、用于区分作业
            </div>
            <div>
              2.数据目录：算例具体存储位置为一个文件夹，内有运行算例必要的输入文件等
            </div>
            <div>
              3.单节点核心数：设置范围1-56 ，单节点最多56核心，通过设置单节点核心数和节点数来设置并行核心数
            </div>
            <div>
              4.节点数：跨节点运行的节点数，节点数*单节点核心=并行核心数，本例子设置的为单节点56核心
            </div>
            <div>
              5.最大运行时长：该选项不是必填项，输入单位为分钟，设置运行最大时长，超过时长自动终止作业
            </div>
            <div>
              6.选择算例文件：选择输入文件，gaussian输入文件格式为.gif后缀文件参数选择完成后点击运行进行算例提交
            </div>
          </div>
          <div>
            3、参数选择完成后点击运行进行算例提交作业，提交后可以通过作业管理查看作业运行状态
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/gaussian1-big.png'),
      imageUrl2: require('../assets/manual/gaussian2-big.png')
    }
  },
  mounted() {},
  methods: {
    showImagePreview(val) {
      if (document.body.getElementsByClassName('previewContainer').length !== 0) {
        document.body.removeChild(document.body.getElementsByClassName('previewContainer')[0])
      }
      const image = new Image()
      // image.src = this.imageUrl
      if (val === 1) {
        image.src = this.imageUrl1
      } else {
        image.src = this.imageUrl2
      }
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div')
        previewContainer.className = 'previewContainer'
        previewContainer.style.position = 'fixed'
        previewContainer.style.top = 0
        previewContainer.style.bottom = 0
        previewContainer.style.left = 0
        previewContainer.style.right = 0
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContainer.style.display = 'flex'
        // previewContainer.style.justifyContent = 'center'
        previewContainer.style.alignItems = 'center'
        document.body.appendChild(previewContainer)
        // 在弹出层中添加图片
        const previewImage = document.createElement('img')
        // previewImage.src = this.imageUrl
        if (val == 1) {
          previewImage.src = this.imageUrl1
        } else {
          previewImage.src = this.imageUrl2
        }
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewContainer.appendChild(previewImage)
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer)
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.manual-gaussian{
  .box-content{
    .img-box{
      padding-left: 20px;
    }
    .space-tab{
      div{
        padding-bottom: 16px;
      }
    }
  }
}
</style>
