<template>
  <div id="manualFile" class="manual-file">
    <div class="video">
      <!-- <img src="@/assets/manual/video3.png" alt="超算云桌面用户手册"> -->
      <video src="@/assets/viod/file.mp4" controls="controls" />

    </div>
    <div class="content">
      <div id="manualFileTree" class="model-box not-home">
        <h1>1、左侧树操作</h1>
        <div class="box-content">
          <div>
            右键某个文件夹，可进行对应的剪切、复制、粘贴、删除操作。如下，右键“xinxin”-剪切，再右键“haohao”，选择“粘贴”，即实现将文件夹“xinxin”移动到“haohao”目录
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/dir.png" alt="超算云桌面用户手册" @click="showImagePreview(1)"> -->
            <viewer :images="imageUrl1" :options="options">
              <img :src="imageUrl1" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div id="manualFileList" class="model-box not-home">
        <h1>2、文件列表</h1>
        <div class="box-content">
          <div>
            单击左侧树的对应文件夹进行右侧列表的对应操作，如下图，单击dbus-1,在dbus-1文件夹下进行操作
          </div>
          <div class="img-box">
            <!-- <img src="@/assets/manual/file1.png" alt="超算云桌面用户手册" @click="showImagePreview(2)"> -->
            <viewer :images="imageUrl2" :options="options">
              <img :src="imageUrl2" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div id="manualFileUpload" class="model-box not-home">
        <h1>3、文件上传</h1>
        <div class="box-content">
          <div class="img-box">
            <!-- <img src="@/assets/manual/file2.png" alt="超算云桌面用户手册" @click="showImagePreview(3)"> -->
            <viewer :images="imageUrl3" :options="options">
              <img :src="imageUrl3" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div id="manualAddDir" class="model-box not-home">
        <h1>4、新增目录</h1>
        <div class="box-content">
          <div class="img-box">
            <!-- <img src="@/assets/manual/file3.png" alt="超算云桌面用户手册" @click="showImagePreview(4)"> -->
            <viewer :images="imageUrl4" :options="options">
              <img :src="imageUrl4" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div id="manualAddFile" class="model-box not-home">
        <h1>5、新建文件</h1>
        <div class="box-content">
          <div class="img-box">
            <!-- <img src="@/assets/manual/file4.png" alt="超算云桌面用户手册" @click="showImagePreview(5)"> -->
            <viewer :images="imageUrl5" :options="options">
              <img :src="imageUrl5" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div id="manualDeleteFile" class="model-box not-home">
        <h1>6、删除文件</h1>
        <div class="box-content">
          <div class="img-box">
            <!-- <img src="@/assets/manual/file5.png" alt="超算云桌面用户手册" @click="showImagePreview(6)"> -->
            <viewer :images="imageUrl6" :options="options">
              <img :src="imageUrl6" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div id="manualDownloadFile" class="model-box not-home">
        <h1>7、下载文件</h1>
        <div class="box-content">
          <div class="img-box">
            <!-- <img src="@/assets/manual/file6.png" alt="超算云桌面用户手册" @click="showImagePreview(7)"> -->
            <viewer :images="imageUrl7" :options="options">
              <img :src="imageUrl7" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div id="manualCompressFile" class="model-box not-home">
        <h1>8、压缩文件</h1>
        <div class="box-content">
          <div class="img-box">
            <!-- <img src="@/assets/manual/file6.png" alt="超算云桌面用户手册" @click="showImagePreview(7)"> -->
            <viewer :images="imageUrl9" :options="options">
              <img :src="imageUrl9" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div id="manualDecompressFile" class="model-box not-home">
        <h1>9、解压文件</h1>
        <div class="box-content">
          <div class="img-box">
            <!-- <img src="@/assets/manual/file6.png" alt="超算云桌面用户手册" @click="showImagePreview(7)"> -->
            <viewer :images="imageUrl10" :options="options">
              <img :src="imageUrl10" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div id="manualRecordFile" class="model-box not-home">
        <h1>10、传输记录</h1>
        <div class="box-content">
          <div class="img-box">
            <!-- <img src="@/assets/manual/file6.png" alt="超算云桌面用户手册" @click="showImagePreview(7)"> -->
            <viewer :images="imageUrl11" :options="options">
              <img :src="imageUrl11" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
      <div id="manualOtherFile" class="model-box not-home">
        <h1>11、剪切、复制、粘贴、重命名</h1>
        <div class="box-content">
          <div class="img-box">
            <!-- <img src="@/assets/manual/file7.png" alt="超算云桌面用户手册" @click="showImagePreview(8)"> -->
            <viewer :images="imageUrl8" :options="options">
              <img :src="imageUrl8" alt="超算云桌面用户手册">
            </viewer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageUrl1: require('../assets/manual/dir-big.png'),
      imageUrl2: require('../assets/manual/file1-big.png'),
      imageUrl3: require('../assets/manual/file2-big.png'),
      imageUrl4: require('../assets/manual/file3-big.png'),
      imageUrl5: require('../assets/manual/file4-big.png'),
      imageUrl6: require('../assets/manual/file5-big.png'),
      imageUrl7: require('../assets/manual/file6-big.png'),
      imageUrl8: require('../assets/manual/file7-big.png'),
      imageUrl9: require('../assets/manual/file8-big.png'),
      imageUrl10: require('../assets/manual/file9-big.png'),
      imageUrl11: require('../assets/manual/file10-big.png')
    }
  },
  mounted() {
    this.$nextTick(() => {
      console.log(sessionStorage.manualFileIdType)
      if (sessionStorage.manualFileIdType === 'fileTree') {
        document.querySelector('#manualFileTree').scrollIntoView({ behavior: 'smooth' })
      } else if (sessionStorage.manualFileIdType === 'fileList') {
        document.querySelector('#manualFileList').scrollIntoView({ behavior: 'smooth' })
      } else if (sessionStorage.manualFileIdType === 'fileUpload') {
        document.querySelector('#manualFileUpload').scrollIntoView({ behavior: 'smooth' })
      } else if (sessionStorage.manualFileIdType === 'addDir') {
        document.querySelector('#manualAddDir').scrollIntoView({ behavior: 'smooth' })
      } else if (sessionStorage.manualFileIdType === 'addFile') {
        document.querySelector('#manualAddFile').scrollIntoView({ behavior: 'smooth' })
      } else if (sessionStorage.manualFileIdType === 'deleteFile') {
        document.querySelector('#manualDeleteFile').scrollIntoView({ behavior: 'smooth' })
      } else if (sessionStorage.manualFileIdType === 'downloadFile') {
        document.querySelector('#manualDownloadFile').scrollIntoView({ behavior: 'smooth' })
      } else if (sessionStorage.manualFileIdType === 'compressFile') {
        document.querySelector('#manualCompressFile').scrollIntoView({ behavior: 'smooth' })
      } else if (sessionStorage.manualFileIdType === 'decompressFile') {
        document.querySelector('#manualDecompressFile').scrollIntoView({ behavior: 'smooth' })
      } else if (sessionStorage.manualFileIdType === 'recordFile') {
        document.querySelector('#manualRecordFile').scrollIntoView({ behavior: 'smooth' })
      } else if (sessionStorage.manualFileIdType === 'otherFile') {
        document.querySelector('#manualOtherFile').scrollIntoView({ behavior: 'smooth' })
      }
    })
  },
  methods: {
    showImagePreview(val) {
      if (document.body.getElementsByClassName('previewContainer').length !== 0) {
        document.body.removeChild(document.body.getElementsByClassName('previewContainer')[0])
      }
      const image = new Image()
      // image.src = this.imageUrl
      if (val === 1) {
        image.src = this.imageUrl1
      } else if (val === 2) {
        image.src = this.imageUrl2
      } else if (val === 3) {
        image.src = this.imageUrl3
      } else if (val === 4) {
        image.src = this.imageUrl4
      } else if (val === 5) {
        image.src = this.imageUrl5
      } else if (val === 6) {
        image.src = this.imageUrl6
      } else if (val === 7) {
        image.src = this.imageUrl7
      } else {
        image.src = this.imageUrl8
      }
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div')
        previewContainer.className = 'previewContainer'
        previewContainer.style.position = 'fixed'
        previewContainer.style.top = 0
        previewContainer.style.bottom = 0
        previewContainer.style.left = 0
        previewContainer.style.right = 0
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)'
        previewContainer.style.display = 'flex'
        // previewContainer.style.justifyContent = 'center'
        previewContainer.style.alignItems = 'center'
        document.body.appendChild(previewContainer)
        // 在弹出层中添加图片
        const previewImage = document.createElement('img')
        // previewImage.src = this.imageUrl
        if (val == 1) {
          previewImage.src = this.imageUrl1
        } else if (val === 2) {
          previewImage.src = this.imageUrl2
        } else if (val === 3) {
          previewImage.src = this.imageUrl3
        } else if (val === 4) {
          previewImage.src = this.imageUrl4
        } else if (val === 5) {
          previewImage.src = this.imageUrl5
        } else if (val === 6) {
          previewImage.src = this.imageUrl6
        } else if (val === 7) {
          previewImage.src = this.imageUrl7
        } else {
          previewImage.src = this.imageUrl8
        }
        previewImage.style.maxWidth = '80%'
        previewImage.style.maxHeight = '80%'
        previewContainer.appendChild(previewImage)
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer)
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.manual-file{
  video{
    width: 100%;
    height: 100%;
  }
  .box-content{
    .img-box{
      padding-left: 20px;
    }
  }
}
</style>
