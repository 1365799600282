<template>
  <div class="users-manual">
    <!-- <el-drawer ref="usersManualDrawerRef" v-model="drawer" :title="标题" :size="size" :with-header="false" @open="openDrawer" @close="closeDrawer">
      <div class="title">{{ usersManualId }}</div>
    </el-drawer> -->
    <div v-if="drawer" class="drawer-box">
      <div class="header">
        <manual-header />
        <svg-icon icon-class="close" @click="closeDrawer" />
      </div>
      <div class="content">
        <manual-bind-wechat v-if="usersManualId==1" />
        <manual-official-account v-else-if="usersManualId==2" />
        <manual-install-or-uninstall v-else-if="usersManualId==3149252963594628" />
        <manual-lammps v-else-if="usersManualId==6153013083878343" />
        <manual-qe v-else-if="usersManualId==1221482298789444" />
        <manual-cp2k v-else-if="usersManualId==6258729541266953" />
        <manual-vasp v-else-if="usersManualId==3350359504604818" />
        <manual-abaqus v-else-if="usersManualId==8889162036467440" />
        <manual-matlab v-else-if="usersManualId==8889162036467443" />
        <manual-comsol v-else-if="usersManualId==8889162036467447" />
        <manual-starccm v-else-if="usersManualId==2059033130546053" />
        <manual-ms v-else-if="usersManualId==8889162036467442" />
        <manual-lsdyna v-else-if="usersManualId==3143428603900800" />
        <manual-mechanical v-else-if="usersManualId==8889162036467449" />
        <manual-fluent v-else-if="usersManualId==3296931928216553" />
        <manual-gaussian v-else-if="usersManualId==7279991280277965" />
        <manual-recharge v-else-if="usersManualId==2946147018625071" />
        <!-- <manual-account v-else-if="usersManualId==8889162036467441" /> -->
        <manual-vscode v-else-if="usersManualId==2108023696651968" />
        <manual-desktop-job v-else-if="usersManualId==3" />
        <manual-terminal v-else-if="usersManualId==5" />
        <manual-job v-else-if="usersManualId==3145878294710521" />
        <manual-file v-else-if="usersManualId==7100048350041969" />
        <manual-problem-collection v-else-if="usersManualId==7" />
        <manual-forget-psd v-else-if="usersManualId==6" />
        <manual-gromacs v-else-if="usersManualId==1382943853206252" />
        <manual-bill-detail v-else-if="usersManualId==-2" />
        <manual-bill-view v-else-if="usersManualId==0" />
        <manual-invoice v-else-if="usersManualId==-4" />
        <manual-month v-else-if="usersManualId==-1" />
        <manual-recharge-record v-else-if="usersManualId==-3" />
        <manual-ai v-else-if="usersManualId==8889162036467450 || usersManualId==8889162036467451 || usersManualId==8889162036467452 || usersManualId==8889162036467453 || usersManualId==8889162036467499" />
        <manual-dir v-else />
      </div>
    </div>
  </div>
</template>

<script>
import ManualHeader from '@/components/ManualHeader'
import ManualDir from '@/components/ManualDir'
import ManualBindWechat from '@/components/ManualBindWechat'
import ManualOfficialAccount from '@/components/ManualOfficialAccount'
import ManualInstallOrUninstall from '@/components/ManualInstallOrUninstall'
import ManualLammps from '@/components/ManualLammps'
import ManualQe from '@/components/ManualQe'
import ManualCp2k from '@/components/ManualCp2k'
import ManualVasp from '@/components/ManualVasp'
import ManualAbaqus from '@/components/ManualAbaqus'
import ManualMatlab from '@/components/ManualMatlab'
import ManualComsol from '@/components/ManualComsol'
import ManualStarccm from '@/components/ManualStarccm'
import ManualMs from '@/components/ManualMs'
import ManualLsdyna from '@/components/ManualLsdyna'
import ManualMechanical from '@/components/ManualMechanical'
import ManualFluent from '@/components/ManualFluent'
import ManualGaussian from '@/components/ManualGaussian'
import ManualRecharge from '@/components/ManualRecharge'
import ManualAccount from '@/components/ManualAccount'
import ManualVscode from '@/components/ManualVscode'
import ManualDesktopJob from '@/components/ManualDesktopJob'
import ManualTerminal from '@/components/ManualTerminal'
import ManualJob from '@/components/ManualJob'
import ManualFile from '@/components/ManualFile'
import ManualForgetPsd from '@/components/ManualForgetPsd'
import ManualProblemCollection from '@/components/ManualProblemCollection'
import ManualBillDetail from '@/components/ManualBillDetail'
import ManualBillView from '@/components/ManualBillView'
import ManualInvoice from '@/components/ManualInvoice'
import ManualMonth from '@/components/ManualMonth'
import ManualRechargeRecord from '@/components/ManualRechargeRecord'
import ManualAi from '@/components/ManualAi'
import ManualGromacs from '../components/ManualGromacs.vue'
export default {
  name: 'UsersManual',
  components: {
    ManualBillDetail,
    ManualBillView,
    ManualInvoice,
    ManualMonth,
    ManualRechargeRecord,
    ManualHeader,
    ManualDir,
    ManualBindWechat,
    ManualOfficialAccount,
    ManualInstallOrUninstall,
    ManualLammps,
    ManualQe,
    ManualCp2k,
    ManualVasp,
    ManualAbaqus,
    ManualMatlab,
    ManualComsol,
    ManualStarccm,
    ManualMs,
    ManualLsdyna,
    ManualMechanical,
    ManualFluent,
    ManualGaussian,
    ManualRecharge,
    // ManualAccount,
    ManualVscode,
    ManualDesktopJob,
    ManualTerminal,
    ManualJob,
    ManualFile,
    ManualForgetPsd,
    ManualProblemCollection,
    ManualAi,
    ManualGromacs
  },
  data() {
    return {
      drawer: false,
      position: 'top',
      size: '400px !important'
    }
  },
  computed: {
    usersManualId() {
      var usersManualId = null
      usersManualId = this.$store.state.core.usersManualId
      return usersManualId
    },
    userAccountManualId() {
      var userAccountManualId = null
      userAccountManualId = this.$store.state.core.userAccountManualId
      return userAccountManualId
    }
  },
  methods: {
    openUsersManualDrawer() {
      this.drawer = true
    },
    openDrawer() {},
    closeDrawer() {
      if (this.usersManualId === 3149252963594628) {
        sessionStorage.manualAppIdType = 'install'
      }
      if (this.usersManualId === 7100048350041969) {
        sessionStorage.manualFileIdType = 'file'
      }
      this.drawer = false
    }
  }
}
</script>

<style scoped lang="less">
.users-manual{
  width: 400px;
  ::v-deep .el-overlay{
    background-color: transparent;
  }
  .drawer-box{
    height: calc(100% - 36px);
    position: fixed;
    bottom: 0;
    right: 0;
    width: 400px;
    background-color: #fff;
    overflow: auto;
    .content{
      padding: 65px 13px 16px 14px;
    }
    .header{
      padding: 0 23px 0 20px;
      height: 49px;
      line-height: 49px;
      border-bottom: 1px solid #E7E7E7;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #000000;
      position: fixed;
      top: 36px;
      right: 0;
      background-color: #fff;
      width: 400px;
      z-index:99;
      .svg-icon{
        width: 10px;
        height: 10px;
      }
    }
  }
}
</style>
