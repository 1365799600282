<template>
  <div
    v-show="isShow"
    class="context-menu-section"
    :style="{
      top: position.y + 'px',
      left: position.x + 'px'
    }"
  >
    <div class="context-menu">
      <ul>
        <li
          v-for="item in list"
          :key="item.id"
          :class="{ disabled: item.disabled, divide: item.type == 'divide', 'list-item': item.type !== 'divide' }"
          @click="onClick(item)"
        >
          <svg-icon v-show="item.type!='divide'" :icon-class="item.icon" />{{ item.text }}
          <!-- <i class="fa" :class="item.icon" aria-hidden="true"></i> -->
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ContextMenu',
  data() {
    return {
      icon: [
        {
          id: '1',
          text: '启动',
          type: 'default',
          disabled: false,
          icon: 'start',
          action: 'core/openApp'
        },
        {
          type: 'divide'
        },
        {
          id: '2',
          text: '删除',
          type: 'default',
          disabled: false,
          icon: 'uninstall',
          action: 'core/uninstall'
        }
      ],
      wall: [
        // {
        //   id: '3',
        //   text: '壁纸设置',
        //   type: 'default',
        //   icon: 'bg',
        //   disabled: false,
        //   action: 'core/display'
        // },
        // {
        //   type: 'divide'
        // },
        {
          id: '2',
          text: '刷新',
          type: 'default',
          icon: 'refresh',
          disabled: false,
          action: 'core/refresh'
        }
        // {
        //   type: 'divide'
        // },
        // {
        //   id: '3',
        //   text: '属性',
        //   type: 'default',
        //   icon: 'attribute',
        //   disabled: false,
        //   action: 'core/display'
        // }
      ]
    }
  },
  computed: {
    list() {
      if (this.$store.state.core.contextMenu.type === 'wall') {
        return this.wall
      } else {
        return this.icon
      }
    },
    position() {
      return this.$store.state.core.contextMenu
    },
    isShow() {
      return this.position.x > 0 && this.position.y > 0
    }
  },
  methods: {
    onClick(item) {
      const date = this.$store.state.core.contextMenu.data
      this.$store.dispatch(item.action, date)
      this.$store.commit('core/CLEAN_CONTEXT_MENU')
    }
  }
}
</script>

<style lang="less" rel="stylesheet/less">
.context-menu-section {
	position: absolute;
}
.context-menu {
	margin: 0;
	padding: 0;
  width: 200px;
	ul {
		background: rgba(255, 255, 255, 0.8);
		width: 100%;
		padding: 5px 0;
		list-style: none;
		border-radius: 5px;
		.list-item {
			cursor: default;
			color: #313A46;
			padding: 5px 10px;
      .svg-icon{
        margin-right: 10px;
        vertical-align: middle;
        width: 15px;
        height: 15px;
      }
			&:hover {
				background: #F2F9FF;
			}
			&.disabled {
				color: #999;
			}
		}
		.divide {
			border-top: 1px solid #E8EEF3;
			// margin: 5px;
		}
	}
}
</style>
